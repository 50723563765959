<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">个人资料</h1>
				<div class="clearfix"></div>
			</div>
			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->
			<div class="box-bd">
				<div class="userInfo">
					<!-- <div class="box">
						<div class="text">
							
							头像：
						</div>
						<div class="rt">
							<el-upload
							  class="avatar-uploader"
							  accept=".jpeg,.jpg,.png"
							  :action="uploadUrl"
							  :show-file-list="false"
							  :on-success="handleAvatarSuccess"
							  :before-upload="beforeAvatarUpload">
							  <img v-if="imageUrl" :src="imageUrl" class="avatar">
							  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</div>
					
					</div> -->
					<div class="box">
						<div class="text">
							<!-- <span class="redxx">*</span> -->
							昵称：
						</div>
						<div class="rt">
							<input type="text" v-model="user.Alias" class="input" placeholder="请输入昵称">
						</div>

					</div>
					<div class="box">
						<div class="text">
							<!-- <span class="redxx">*</span> -->
							性别：
						</div>
						<div class="rt">
							<el-radio-group v-model="user.Gender">
								<!-- <el-radio label="保密">保密</el-radio> -->
								<el-radio label="男">男</el-radio>
								<el-radio label="女">女</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="box">
						<div class="text">
							<!-- <span class="redxx">*</span> -->
							居住地：
						</div>
						<div class="rt">
							<input type="text" v-model="user.Address" class="input" placeholder="居住地">
						</div>
					</div>
					<button class="btn btn-small btn-primary Personalbtn" :disabled="isChecked" @click="UpdateUseriInfo" >确认保存</button>
				</div>
			</div>
			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Order',
		data() {
			return {
				radio: '1',
				user: {
					Alias: '', //昵称
					Gender: '', //性别
					Address: '' //地址
				},
				uploadUrl: this.$target + '/UploadUploadFile',
				isChecked: false,
				
				imageUrl:'',
			};
		},
		created() {
			this.GetUserInfo()
		},
		methods: {
			async GetUserInfo() {
				const res = await this.postdata("/User/GetUser")
				if (res.code == 200) {
					this.user = res.data
				}
			},
			async UpdateUseriInfo() {
				this.isChecked = true
				const res = await this.postdata('/User/OperationUser', this.user)
				if (res.code == 200) {
					this.GetUserInfo()
					this.notifySucceed("保存成功");
				}
				this.isChecked = false
			},
			 handleAvatarSuccess(res, file) {
			        this.imageUrl = URL.createObjectURL(file.raw);
					console.log(this.imageUrl)
			      },
			      beforeAvatarUpload(file) {
					  console.log(file)
					  var isType = false
					  console.log(file.type === 'image/jpeg' , file.type === 'image/jpg' , file.type === 'image/png')
					if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'){
						console.log(123)
						isType = true
					}
					else{
						this.$message.error('上传头像图片只能是 JPEG，JPG，PNG 格式!');
					}
			        const isLt2M = file.size / 1024 / 1024 < 2;
			        if (!isLt2M) {
			          this.$message.error('上传头像图片大小不能超过 2MB!');
			        }
					console.log(isType,isLt2M,isType && isLt2M)
			        return isType && isLt2M;
			      }

		}

	}
</script>
<style scoped>

	.avatar-uploader {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
		transition: all 0.2s;
	  }
	  .avatar-uploader:hover {
	    border-color: #409EFF;
		transition: all 0.2s;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 100px;
	    height: 100px;
	    line-height: 100px;
	    text-align: center;
	  }
	  .avatar {
	    width: 100px;
	    height: 100px;
	    display: block;
	  }
	
	/* 订单为空的时候显示的内容CSS */
	.userInfo .Personalbtn {
		margin-top: 16px;
		color: #fff;
		background-color: #e23435;
		border-color: #e23435;
	}

	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}

	.userInfo .text {
		width: 80px;
		font-size: 15px;
	}

	.userInfo .rt {}

	.userInfo .rt .input {
		border: 1px solid #bbb;
		font-size: 15px;
		line-height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}



	.box-bd {
		margin-top: 16px;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #603c1d;
		border-color: #603c1d;
		color: #fff;
		font-size: 14px;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	/* 订单为空的时候显示的内容CSS END */
</style>
